html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.section {
  /* background-color: red; */

  /* FLEX VERTICAL */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.child {
  background-color: green;
}

.childFlex {
  width: 100%;
  height: 100%;
  background-color: orange;
}

.childFix {
  width: 100px;
  height: 100px;
  background-color: purple;
}

.container {
  /* background-color: pink; */

  /* FLEX VERTICAL */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /*  */
  margin-top: 25px;
  max-width: 840px;
}

/* .growFlex {
  flex-grow: 1;
} */

.fullWidth {
  width: 100%;
  /* flex-grow: 1; */
  /* overflow: hidden; */
}

.flexV {
  /* FLEX VERTICAL */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.defaultRowGap {
  row-gap: 10px;
}

.gridItem {
  margin-bottom: 30px;
  max-width: 100%;
}

.flexH {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: stretch;
}

.alignCenter {
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

.alignLeft {
  align-items: flex-start;
}

.stretch {
  align-items: stretch;
}

.spacer {
  flex-grow: 1;
}

.flexGrow1 {
  flex-grow: 1;
}

.debug {
  border: 4px solid red;
  background-color: magenta;
}

.debugText {
  font-size: xx-large;
}

.defaultPadding {
  padding: 10px;
}

.defaultRowGap {
  row-gap: 20px;
}

.paddingVertical {
  padding-top: 10px;
  padding-bottom: 10px;
}

:root {
  --theme-color: 0, 140, 207;
}

.cardHeader {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(var(--theme-color), 0.08);
}

.tiptap-vuetify-editor .ProseMirror {
  min-height: 200px;
}

.x-icon {
  height: 1em;
  width: 1em;
  top: 0.125em;
  position: relative;
  margin-right: 5px;
}

.bsk-btn {
  color: #333;
  background-color: #eee;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  display: inline-block;
}

.questionContainer {
  /* FLEX VERTICAL */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;

  padding: 10px;
}

.formContainer {
  /* FLEX VERTICAL */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;

  background-color: rgba(var(--theme-color), 0.02);
}

.topToolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: stretch;
  justify-content: flex-end;
}

.cardsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
}

.mainContainer {
  /* background-color: magenta; */
  background-color: rgba(var(--theme-color), 1);
  width: 100vw;
  height: 100vh;
}

.centeredInContainer {
  display: block;
  /* background-color: red; */

  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;

  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.authContainer {
  min-width: 330px;
  min-height: 400px;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#confirmation {
  color: green;
}

#error {
  color: red;
}

.placeholderCard {
  background-color: #f5f5f9;
  padding: 10px;
  min-height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .elo {
  color: #2ab7ca;
  color: #fed766;
  color: #f4b6c2;
  color: #005b96;
  color: #6497b1;
  color: #fe8a71;
  color: #f9caa7;
  color: #fe9c8f;
  color: #83d0c9;
  color: #009688;
  color: #f37736;
  color: #dfa290;
  color: #88d8b0;
  color: #96ceb4;
  color: #ff8b94;
  color: #a8e6cf;
  color: #ff5588;
  color: #f1c27d;
} */
